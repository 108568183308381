import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { PageContext } from "../context"

const IndexPage = ({ location }) => {
  return (
    <PageContext.Provider value={{location}} >
      <Layout>
        <SEO title="Home" />
        <h1>Home</h1>
      </Layout>
    </PageContext.Provider>
  )
}

export default IndexPage
